import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // For navigation after ordering reports
import { getVinDecode, orderVdr } from "../../Queries/VdrApi/vdrApi";

const VdrOrder = () => {
  const [vin, setVin] = useState(""); // For VIN input
  const [vinDecodes, setVinDecodes] = useState([]); // For storing VIN decode results
  const navigate = useNavigate(); // For navigation

  // Simulate the DecodeVin function that would send the VinDecodeCommand
  const decodeVin = async () => {
    const decodeInfo = await sendVinDecodeCommand(vin);

    setVinDecodes([...vinDecodes, { ...decodeInfo, Selected: true }]);
    setVin(""); // Reset the VIN input
  };

  // Simulate API call to send VinDecodeCommand
  const sendVinDecodeCommand = async (vin) => {
    return getVinDecode(vin);
  };

  // Simulate ordering reports for selected VINs
  const orderSelectedReports = async () => {
    const selectedVins = vinDecodes.filter((vinDecode) => vinDecode.Selected);
    debugger;
    for (const vinDecode of selectedVins) {
      await orderVdr(vinDecode.vin); // Simulate ordering the report
    }
    navigate("/vdrhistory"); // Navigate to report history
  };

  // Toggle selected state for a VIN
  const toggleReportOrder = (vinDecode) => {
    setVinDecodes(
      vinDecodes.map((v) =>
        v.vin === vinDecode.vin ? { ...v, Selected: !v.Selected } : v
      )
    );
  };

  return (
    <div className="row">
      <div className="col-lg-4"></div>
      <div className="col-lg-4 col-xs-12">
        <div className="row">
          <div className="input-group mb-3">
            <span className="input-group-text" id="vin_label">
              Enter VIN:
            </span>
            <input
              type="text"
              value={vin}
              onChange={(e) => setVin(e.target.value)} // Update VIN input state
              className="form-control"
              placeholder="1234567890ABCDEFG"
              aria-label="Vin"
              aria-describedby="vin_label"
            />
            <button className="btn btn-primary" onClick={decodeVin}>
              Decode
            </button>
          </div>
        </div>
        <div className="row mb-1">
          <button className="btn btn-primary">Scan Barcode</button>
        </div>
        <div className="row">
          <button className="btn btn-primary">Use Camera</button>
        </div>
        <br />
        {vinDecodes.length > 0 && (
          <div className="row">
                {vinDecodes.map((vinDecode, index) => (
                  <>
                  <div class="card mb-2">
                    <div class="card-body">
                        <h5 class="card-title">{`${vinDecode.year} ${vinDecode.make} ${vinDecode.model} ${vinDecode.trim}`}</h5>
                        <h6 class="card-subtitle mb-2 text-body-secondary">{vinDecode.vin}</h6>
                        <div class="form-check form-switch">
                            <input 
                                class="form-check-input" 
                                type="checkbox" 
                                role="switch" 
                                id={`orderCard${index}`}
                                onClick={() => toggleReportOrder(vinDecode)}
                                checked={vinDecode.Selected}
                            />
                            <label class="form-check-label" for="flexSwitchCheckDefault">Order?</label>
                        </div>
                    </div>
                </div>
                    
                  </>
                ))}
          </div>
        )}
        {vinDecodes.length > 0 && (
          <div className="row">
            <button className="btn btn-success" onClick={orderSelectedReports}>
              Order Reports
            </button>
          </div>
        )}
      </div>
      <div className="col-lg-4"></div>
    </div>
  );
};

export default VdrOrder;
