import { getToken, handleResponse } from "../fetch"; // Ensure these are correctly imported
import { protectedResources } from "../../Authentication/authConfig";

// Function to handle the PagedResponse of VehicleDamageReports by User
export const getVdrByUser = async (pageNumber, recordsPerPage, searchTerms = "") => {
    const method = "GET";
    debugger;
    try {
        if(isNaN(pageNumber)) pageNumber = 1;
        if(isNaN(recordsPerPage)) recordsPerPage = 20;

        // Build the OData query string
        let url = `${protectedResources.vdrApi.endpoint}/odata/VehicleDamageReport?` +
            `$top=${recordsPerPage}` +
            `&$skip=${(pageNumber - 1) * recordsPerPage}` +
            `&$count=true` +
            `&$expand=VinDecodeInfo,DamageDetails,VdrSources,RecallDetails` +
            `&$orderby=LookupDate desc`;
        debugger;
        if (searchTerms.trim() !== "") {
            debugger;
            url += `&$search=${formatSearchQuery(searchTerms)}`;
        }

        // Get the access token
        const accessToken = await getToken(
            url,
            protectedResources.vdrApi.scopes.access, 
            method
        );

        // Set up headers with Bearer token
        const headers = new Headers();
        headers.append("Authorization", `Bearer ${accessToken}`);

        // Send the GET request
        const response = await fetch(url, { method, headers });

        // Handle the response using the common handleResponse function
        return handleResponse(response, url, { method });

    } catch (error) {
        console.error("Error in fetching vehicle damage reports: ", error);
        return null;
    }
};

// Function to get individual Vehicle Damage Report by ID
export const getVdrById = async (vdrId) => {
    const method = "GET";
    try {
        const url = `${protectedResources.vdrApi.endpoint}/odata/VehicleDamageReport(${vdrId})` +
            `?$expand=VinDecodeInfo,DamageDetails,VdrSources,RecallDetails`;

        // Get the access token
        const accessToken = await getToken(
            url,
            protectedResources.vdrApi.scopes.access, // Ensure proper scopes
            method
        );

        // Set up headers with Bearer token
        const headers = new Headers();
        headers.append("Authorization", `Bearer ${accessToken}`);

        // Send the GET request
        const response = await fetch(url, { method, headers });

        // Handle the response using the common handleResponse function
        return handleResponse(response, url, { method });

    } catch (error) {
        console.error("Error fetching Vehicle Damage Report: ", error);
        return null;
    }
};

// Helper function to format search terms as a query
const formatSearchQuery = (searchTerms) => {
    const searchTermsArray = searchTerms.split(" ").filter(term => term.trim() !== "");
    const formattedSearchTerms = searchTermsArray.map(term => `"${term}"`);
    return formattedSearchTerms.join(" AND ");
};


export const getVinDecode = async (vin) => {
    const method = "GET";
    try {
        const url = `${protectedResources.vdrApi.endpoint}/DecodeVin?Vin=${vin}`;
        // Get the access token
        const accessToken = await getToken(
            url,
            protectedResources.vdrApi.scopes.access, // Ensure proper scopes
            method
        );

        // Set up headers with Bearer token
        const headers = new Headers();
        headers.append("Authorization", `Bearer ${accessToken}`);

        // Send the GET request\
        const response = await fetch(url, { method, headers });

        // Handle the response using the common handleResponse function
        return handleResponse(response, url, { method });

    } catch (error) {
        console.error("Error fetching Vehicle Damage Report: ", error);
        return null;
    }
};

export const orderVdr = async (vin) => {
    const method = "GET";
    try {
        const url = `${protectedResources.vdrApi.endpoint}/OrderVin?Vin=${vin}`;

        // Get the access token
        const accessToken = await getToken(
            url,
            protectedResources.vdrApi.scopes.access, // Ensure proper scopes
            method
        );

        // Set up headers with Bearer token
        const headers = new Headers();
        headers.append("Authorization", `Bearer ${accessToken}`);

        // Send the GET request
        const response = await fetch(url, { method, headers });

        // Handle the response using the common handleResponse function
        return handleResponse(response, url, { method });

    } catch (error) {
        console.error("Error fetching Vehicle Damage Report: ", error);
        return null;
    }
};