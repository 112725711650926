import React, { useState, useEffect } from "react";
import { getVdrByUser } from "../../Queries/VdrApi/vdrApi";
import { useNavigate } from "react-router-dom";

const VdrHistory = () => {
  const [vehicleDamageReports, setVehicleDamageReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerms, setSearchTerms] = useState("");
  const [pageNumber, setPageNumber] = useState(1); // Add setPageNumber for changing pages
  const [recordsPerPage] = useState(20);
  const [totalRecords, setTotalRecords] = useState(0);

  // Fetch data on page load and when pageNumber or searchTerms change
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const data = await getVdrByUser(pageNumber, recordsPerPage, searchTerms); // Pass searchTerms too
      setVehicleDamageReports(data?.value ?? []);
      setTotalRecords(data?.["@odata.count"] ?? 0); // Set total records from API response
      setLoading(false);
    };

    fetchData();
  }, [pageNumber, recordsPerPage, searchTerms]); // Update when pageNumber or searchTerms change

  // Handle search input
  const handleSearch = async (e) => {
    const searchValue = e.target.value;
    setSearchTerms(searchValue);
    setPageNumber(1); // Reset to page 1 when performing a new search
  };

  // Pagination controls
  const handleNextPage = () => {
    if (pageNumber * recordsPerPage < totalRecords) {
      setPageNumber((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber((prev) => prev - 1);
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col">
          <div className="row">
            <div className="col">
              <div className="input-group mb-3">
                <span className="input-group-text" id="search_label">
                  Search:
                </span>
                <input
                  type="text"
                  value={searchTerms}
                  onChange={handleSearch}
                  className="form-control"
                  placeholder="VIN Year Make Model"
                  aria-label="Search"
                  aria-describedby="vin_label"
                />
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col text-center">
              <button
                className="btn btn-secondary me-2"
                onClick={handlePreviousPage}
                disabled={pageNumber === 1}
              >
                Previous
              </button>
              <span>Page {pageNumber}</span>
              <button
                className="btn btn-secondary ms-2"
                onClick={handleNextPage}
                disabled={pageNumber * recordsPerPage >= totalRecords}
              >
                Next
              </button>
            </div>
          </div>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <>
              {vehicleDamageReports.length > 0 ? (
                vehicleDamageReports.map((vdr, index) => (
                  <div key={vdr.VehicleDamageReportId}>
                    <div className="card text-center">
                      <div className="card-header">
                        <h4>{`${vdr.VinDecodeInfo.Year} ${vdr.VinDecodeInfo.Make} ${vdr.VinDecodeInfo.Model} ${vdr.VinDecodeInfo.Trim}`}</h4>
                      </div>
                      <div className="card-body">
                        <h6 className="card-title strong">{vdr.Vin}</h6>
                        <div className="card-text">
                          <div className="container">
                            <div className="row">
                              <div className="col">
                                <span>{vdr.UserName} </span>
                                <span>
                                  @{" "}
                                  {vdr.LookupDate
                                    ? new Date(vdr.LookupDate).toLocaleString()
                                    : ""}
                                </span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                <div
                                  className={`m-2 btn ${
                                    vdr.DamageDetails.length > 0
                                      ? "btn-danger"
                                      : "btn-success"
                                  } position-relative`}
                                >
                                  <span className="material-symbols-outlined">
                                    minor_crash
                                  </span>
                                  <span
                                    className={`position-absolute top-0 start-100 translate-middle badge rounded-pill ${
                                      vdr.DamageDetails.length > 0
                                        ? "bg-danger"
                                        : "bg-success"
                                    }`}
                                  >
                                    {vdr.DamageDetails.length}
                                  </span>
                                </div>
                                <div
                                  className={`m-2 btn ${
                                    vdr.RecallDetails.length > 0
                                      ? "btn-danger"
                                      : "btn-success"
                                  } position-relative`}
                                >
                                  <span className="material-symbols-outlined">
                                    campaign
                                  </span>
                                  <span
                                    className={`position-absolute top-0 start-100 translate-middle badge rounded-pill ${
                                      vdr.RecallDetails.length > 0
                                        ? "bg-danger"
                                        : "bg-success"
                                    }`}
                                  >
                                    {vdr.RecallDetails.length}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <a
                            href={`/Vdr/${vdr.VehicleDamageReportId}`}
                            className="btn btn-primary"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View VDR&nbsp;
                            <span className="align-middle material-symbols-outlined">
                              open_in_new
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <br />
                  </div>
                ))
              ) : (
                <div>No records found.</div>
              )}
            </>
          )}
          <div className="row mt-3">
            <div className="col text-center">
              <button
                className="btn btn-secondary me-2"
                onClick={handlePreviousPage}
                disabled={pageNumber === 1}
              >
                Previous
              </button>
              <span>Page {pageNumber}</span>
              <button
                className="btn btn-secondary ms-2"
                onClick={handleNextPage}
                disabled={pageNumber * recordsPerPage >= totalRecords}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VdrHistory;
